.button-default {
  background-color: var(--default);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
  display: inline-block;
  border-radius: 12px;
  cursor: pointer;
}
.button-default__container {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
}
.button-default__text {
  margin-left: 16px;
}
.button-default:hover {
  background-color: var(--primary);
}
.button-default:hover .button-default__text {
  color: var(--default);
}
.button-default:hover .icon_button-record {
  background-position-x: right;
}
.button-default:active,
.button-default:focus {
  box-shadow: 0px 0px 0px 4px var(--default), 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
  background-color: var(--primary);
}
.button-default:active .button-default__text,
.button-default:focus .button-default__text {
  color: var(--default);
}
.button-default:active .icon_button-record,
.button-default:focus .icon_button-record {
  background-position-x: right;
}
.button-default.active {
  box-shadow: 0px 0px 0px 4px var(--default), 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
  background-color: var(--primary);
}
.button-default.active .button-default__text {
  color: var(--default);
}
.button-default.active .icon_button-record {
  background-position-x: right;
}
.button-default:disabled {
  opacity: 0.5;
}
.button-primary {
  background-color: var(--primary);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
  display: inline-block;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
}
.button-primary__container {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
}
.button-primary__icon {
  position: relative;
  z-index: 2;
  filter: invert(var(--icons_primary_inverse));
}
.button-primary__text {
  margin-left: 16px;
  color: var(--text_inverse) !important;
  position: relative;
  z-index: 2;
}
.button-primary .icon_button-record {
  background-position-x: right;
}
.button-primary:hover {
  filter: brightness(80%);
}
.button-primary:hover .button-primary__text {
  color: var(--text_inverse);
}
.button-primary:hover .icon_button-record {
  background-position-x: right;
}
.button-primary:hover .button-primary_active {
  display: block;
}
.button-primary_active {
  display: none;
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 12px;
  z-index: 0;
}
.button-primary:active,
.button-primary:focus {
  box-shadow: 0px 0px 0px 4px var(--default), 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
  background-color: var(--primary);
}
.button-primary:active .button-primary_active,
.button-primary:focus .button-primary_active {
  display: block;
}
.button-primary.active {
  box-shadow: 0px 0px 0px 4px var(--default), 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
  background-color: var(--primary);
}
.button-primary.active .button-primary__text {
  color: var(--default);
}
.button-primary.active .icon_button-record {
  background-position-x: right;
}
.button-primary.active .button-primary_active {
  display: block;
}
.button-primary:disabled {
  opacity: 0.5;
}
.button-primary_input .button-primary__container {
  justify-content: flex-end;
}
.button-link {
  background-color: transparent;
  cursor: pointer;
}
.button-link__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-link__icon {
  display: none;
}
.button-link__text {
  text-decoration: underline;
}
.button-link:hover {
  background-color: transparent;
}
.button-link:hover .button-link__text {
  text-decoration: underline;
}
.button-link:active .button-link__text,
.button-link:focus .button-link__text {
  text-decoration: underline;
}
.button-link.active {
  box-shadow: none;
  background-color: transparent;
}
.button-link.active .button-link__text {
  text-decoration: underline;
}
.button-link:disabled {
  opacity: 0.5;
}
.button-link.button-link_icon .button-link__icon {
  display: block;
  margin-right: 14px;
}
.button-square {
  background-color: transparent;
  padding: 8px;
  border-radius: 10px;
}
.button-square__icon {
  filter: invert(var(--icons_default_inverse));
}
.button-square:hover {
  background-color: var(--primary);
}
.button-square:hover .icon_button-viewing {
  background-position: right;
}
.button-square:active,
.button-square:focus {
  background-color: var(--primary);
  box-shadow: 0px 0px 0px 4px var(--default);
}
.button-square:active .icon_button-viewing,
.button-square:focus .icon_button-viewing {
  background-position: right;
}
.button-square.active {
  background-color: var(--primary);
  box-shadow: 0px 0px 0px 4px var(--default);
}
.button-square.active .icon_button-viewing {
  background-position: right;
}
.button-square:disabled {
  opacity: 0.5;
}
.button-google-calendar {
  padding: 16px;
  color: #fff;
  order: 4;
}
/*
 * ВЫНЕСТИ В ЗАПИСЬ НА ПРИЕМ
 */
.button-branch {
  background-color: var(--default);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
  display: inline-block;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
  max-width: 380px;
}
.button-branch__container {
  display: flex;
  padding: 16px;
  align-items: center;
}
.button-branch__text {
  margin-left: 16px;
  text-align: left;
  color: #707070;
}
.button-branch__text > span {
  display: block;
  font-size: 12px;
  opacity: 0.75;
}
.button-branch:hover {
  background-color: var(--primary);
}
.button-branch:hover .button-branch__text {
  color: var(--default);
}
.button-branch:hover .icon_button-branch,
.button-branch:hover .icon_step-3,
.button-branch:hover .icon_step-2 {
  background-position-x: right;
}
.button-branch:active,
.button-branch:focus {
  box-shadow: 0px 0px 0px 4px var(--default), 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
  background-color: var(--primary);
}
.button-branch:active .button-branch__text,
.button-branch:focus .button-branch__text {
  color: var(--default);
}
.button-branch:active .icon_button-branch,
.button-branch:focus .icon_button-branch {
  background-position-x: right;
}
.button-branch.active {
  box-shadow: 0px 0px 0px 4px var(--default), 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
  background-color: var(--primary);
}
.button-branch.active .button-branch__text {
  color: var(--default);
}
.button-branch.active .button-branch__icon {
  background-position-x: right;
}
.button-branch:disabled {
  opacity: 0.5;
}
.button-time-stamp {
  background-color: var(--default);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.4);
  display: block;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
}
.button-time-stamp__container {
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .button-time-stamp__container {
    padding: 16px;
  }
}
.button-time-stamp__text {
  color: #707070;
}
.button-time-stamp:hover {
  background-color: var(--primary);
}
.button-time-stamp:hover .button-time-stamp__text {
  color: var(--default) !important;
}
.button-time-stamp:active,
.button-time-stamp:focus {
  box-shadow: 0px 0px 0px 4px var(--default), 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
  background-color: var(--primary);
}
.button-time-stamp:active .button-time-stamp__text,
.button-time-stamp:focus .button-time-stamp__text {
  color: var(--default);
}
.button-time-stamp:active .button-time-stamp__icon,
.button-time-stamp:focus .button-time-stamp__icon {
  background-position-x: right;
}
.button-time-stamp.active {
  box-shadow: 0px 0px 0px 4px var(--default), 0px 5px 15px 0px rgba(0, 0, 0, 0.16);
  background-color: var(--primary);
}
.button-time-stamp.active .button-time-stamp__text {
  color: var(--default);
}
.button-time-stamp.active .button-time-stamp__icon {
  background-position-x: right;
}
.button-time-stamp:disabled {
  opacity: 0.3;
}
.button-close-mini {
  background-color: transparent;
  border-radius: 12px;
  width: 40px;
  height: 40px;
  box-shadow: 0px 0px 0px 4px transparent;
}
.button-close {
  background-color: transparent;
  border-radius: 12px;
  width: 80px;
  height: 80px;
  box-shadow: 0px 0px 0px 4px transparent;
}
@media (max-width: 768px) {
  .button-close {
    width: 40px;
    height: 40px;
  }
}
.button-close__container {
  display: flex;
  justify-content: center;
}
.button-close:hover, .button-close-mini:hover {
  box-shadow: 0px 0px 0px 4px var(--text);
}
.button-close:hover .icon_button-close, .button-close-mini:hover .icon_button-close {
  background-position: left;
}
.button-close:active,
.button-close:focus {
  box-shadow: 0px 0px 0px 4px var(--primary);
}
.button-close:active .icon_button-close,
.button-close:focus .icon_button-close {
  background-position: right;
}
.button-close.active {
  box-shadow: 0px 0px 0px 4px var(--primary);
}
.button-close.active .icon_button-close {
  background-position: right;
}
.button-close:disabled {
  opacity: 0.5;
}
/*
 * КОНЕЦ ВЫНЕСТИ В МОДАЛЬНОЕ ОКНО
 */
