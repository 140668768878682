.reception {
  transition: all 0.5s ease;
}

.reception__tab {
  display: flex;
  width: 100%;
  align-items: stretch;
  background-color: #EBEBEB;
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
}
.reception__tab-main {
  display: flex;
  flex: 1;
}
.reception__tab-block {
  padding: 24px;
  width: calc(100% / 6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.reception__tab-block.reception_step {
  flex: 1 1 0px;
}
.reception__tab-block:first-child::before {
  content: none;
}
.reception__tab-block::before {
  content: '';
  display: block;
  width: 1px;
  height: 70px;
  background-color: #c8c8c8;
  position: absolute;
  left: 0px;
  opacity: 0.25;
}
.reception_tab-block-5 {
  width: calc(100% / 5);
}
.reception_tab-block-4 {
  width: calc(100% / 4);
}
.reception_logo {
  padding: 0;
}
.reception_logo a {
  height: 100%;
  max-height: 50px;
}
.reception_logo a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 1024px) {
  .reception_logo {
    display: none;
  }
}
.reception__tab-block.active {
  color: var(--default);
  background-color: var(--primary);
  cursor: pointer;
  position: relative;
}
.reception__tab-block.active .icon_left {
  background-position: right;
}
.reception__tab-block.active .reception__tab-icon,
.reception__tab-block.active .reception__tab-content {
  position: relative;
  z-index: 9;
}
.reception__tab-block.active:hover::after {
  content: '';
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.25);
  width: calc(100% - 2px);
  height: 100%;
  z-index: 0;
}
.reception__tab-block.current-tab::after {
  content: '';
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: calc(100% - 2px);
  height: 100%;
}
.reception__tab-block.current-tab:hover::after {
  content: '';
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: calc(100% - 2px);
  height: 100%;
}
.reception__tab-title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 8px;
}
.reception__tab-block:last-child .reception__tab-title {
  padding-bottom: 0px;
}
.reception__tab-icon {
  padding-right: 32px;
}
.reception__content {
  padding-top: 24px;
}
.reception__mobile {
  display: none;
  border-bottom: 1px solid #707070;
  padding-bottom: 12px;
  padding-top: 12px;
}
.reception__mobile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reception__mobile-content {
  text-align: center;
}
.reception__mobile-title {
  font-size: 28px;
  font-weight: bold;
  padding-bottom: 16px;
}
.reception__mobile-step {
  font-size: 18px;
}
.reception__mobile-prev.hidden span,
.reception__mobile-next.hidden span {
  display: none;
}
.reception__mobile-prev.disabled span,
.reception__mobile-next.disabled span {
  opacity: 0.25;
}
.reception__time {
  display: flex;
  flex-wrap: wrap;
  max-width: 768px;
  margin: 0 auto;
}
.reception__time-block {
  width: calc(100% / 4 - 16px);
  padding: 8px;
}
.reception-services__container {
  width: 100%;
}
.reception-services__type-service {
  width: 100%;
  background-color: var(--primary);
  border-radius: 12px;
  display: flex;
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16);
  color: var(--default);
  align-items: stretch;
  margin-bottom: 48px;
  box-sizing: border-box;
}
.reception-services__type-info {
  max-width: 430px;
  padding: 16px 0px;
  padding-left: 24px;
  
}
@media (max-width: 768px) {
  .reception-services__type-info {
    padding-left: 0px;
  }
}
.reception-services__type-content {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: stretch;
  justify-content: space-around;
}
@media (max-width: 768px) {
  .reception-services__type-content {
    flex-direction: column;
  }
}
.reception-services__type-block {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: relative;
  padding: 10px;
}
.reception-services__type-block:before {
  content: '';
  position: absolute;
  display: block;
  width: 1px;
  height: 60px;
  background-color: #fff;
  left: 0;
  opacity: 0.25;
}
@media (max-width: 768px) {
  .reception-services__type-block:before {
    content: none;
  }
}
.reception-services__type-block:hover {
  background-color: rgba(255, 255, 255, 0.25);
  cursor: pointer;
}
.reception-services__type-block.active {
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  margin: 0px;
  padding: 10px;
}
.reception-services__type-block.active:last-child {
  border-radius: 0px 12px 12px 0px;
}
@media (max-width: 768px) {
  .reception-services__type-block:hover {
    background-color: rgba(255, 255, 255, 0.25);
    cursor: pointer;
    width: calc(100% + 48px);
    position: relative;
    left: -24px;
  }
  .reception-services__type-block.active {
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    width: calc(100% + 48px);
    position: relative;
    left: -24px;
  }
  .reception-services__type-block.active:last-child {
    border-radius: 0px 0px 12px 12px;
  }
}
.reception-services__type-title {
  display: flex;
  align-items: center;
}
.reception-services__type-text div:first-child {
  padding-bottom: 12px;
  text-transform: uppercase;
  font-weight: bold;
}
.reception-services__type-text div:last-child {
  font-size: 14px;
}
.reception-services__type-icon {
  padding-right: 32px;
}
.reception-services__content {
  position: relative;
}
.reception-services__price {
  margin-left: 30px;
}
.reception-services__block {
  width: 100%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  padding: 0px 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #707070;
  cursor: pointer;
  position: relative;
  background-color: var(--default);
  min-height: 60px;
}
.reception-services__block span:first-child {
  flex: 1;
  display: flex;
  height: 100%;
  min-height: 60px;
  align-items: center;
}
.reception-services__sub-services .reception-services__block-before {
  position: relative;
}
.reception-services__sub-services .reception-services__block-before:before {
  background-color: var(--primary);
  border-radius: 12px;
  content: '';
  width: 99%;
  height: 100%;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
}
.reception-services__sub-services .reception-services__block-before {
  padding-left: 16px;
}
.reception-services__sub-services .reception-services__sub-services .reception-services__block-before {
  padding-left: 32px;
}
.reception-services__sub-services .reception-services__sub-services .reception-services__sub-services .reception-services__block-before {
  padding-left: 48px;
}
.reception-services__sub-services .reception-services__sub-services .reception-services__sub-services .reception-services__sub-services .reception-services__block-before {
  padding-left: 64px;
}
.reception-services__sub-services {
  display: none;
}
.reception-services__sub-services.active {
  display: block;
}
.reception-services__message {
  display: none;
}
.reception-services__message.active {
  display: block;
}
.reception-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.reception-date__container {
  display: flex;
  justify-content: center;
  text-align: center;
}
@media (max-width: 991px) {
  .reception-date__container {
    align-items: center;
  }
}
.reception-date__title {
  font-size: 48px;
  font-weight: bold;
  padding-bottom: 18px;
}
.reception-date__time {
  font-size: 28px;
  color: var(--primary);
  font-weight: bold;
  padding-bottom: 20px;
  text-transform: uppercase;
}
.reception-date__info {
  padding-bottom: 0px;
}
.reception-date__info div {
  padding-bottom: 12px;
}
.reception-date__info span {
  font-weight: bold;
}
.reception-date__comment {
  padding-bottom: 28px;
}
.reception-date__comment textarea {
  height: 140px;
}
.reception-date__form {
  margin-bottom: 32px;
  width: 500px;
  max-width: 500px;
}
.reception-date__form input {
  margin-bottom: 16px;
}
.reception-date__input input {
  padding: 24px 24px 24px 24px !important;
  width: calc(100% - 48px) !important;
}
.reception-date__input.error input{
  border: 2px solid var(--danger) ;
}

.reception-date__content {
  flex: 1;
  text-align: left;
  margin-right: 64px;
  margin-bottom: 12px;
}
.reception-date__button-done {
  margin-top: 24px;
}
.reception-date__info-policy {
  width: 500px;
  text-align: left;
  font-size: 14px;
  line-height: 1.4;
}
@media (max-width: 1140px) {
  .reception-date__info-policy {
    width: auto;
    text-align: left;
    margin: 0 auto 12px auto;
  }
}
.reception-date__info-policy a {
  color: var(--primary);
  font-weight: 600;
}
.reception-date__info-policy a:hover {
  color: var(--text);
}
.reception-date__info-policy b {
  font-weight: bold;
}
.reception-doctor__container {
  width: 100%;
  padding-top: 24px;
}
.reception-doctor__period {
  margin-bottom: 24px;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  padding: 24px 0;
}
.reception-doctor__header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  color: #707070;
  text-align: center;
  padding: 0 24px;
  box-sizing: border-box;
}
.reception-doctor__header-block {
  width: calc(100% / 8);
  font-size: 14px;
}
.reception-doctor__header-block div:first-child {
  font-weight: bold;
  padding-bottom: 8px;
}
.reception-doctor__fixed-block {
  display: flex;
  width: 275px;
  max-width: 275px;
  min-width: 275px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.reception-doctor__fixed-block > div:first-child {
  max-width: 72px;
  min-width: 72px;
  width: 72px;
  padding-right: 28px;
}
.reception-doctor__fixed-block > div:last-child {
  flex: 1;
}
.reception-doctor__fixed-block > div > div:first-child {
  font-weight: bold;
  padding-bottom: 8px;
}
.reception-doctor__header-block:first-child div {
  padding-bottom: 0px;
}
.reception-doctor__content {
  width: 100%;
  padding-top: 24px;
}
.reception-doctor__content-block {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  color: #707070;
  background-color: #fff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  margin-bottom: 24px;
}
.reception-doctor__doctor {
  display: flex;
  align-items: center;
  max-width: 275px;
  min-width: 275px;
  width: 275px;
  font-size: 14px;
}
.reception-doctor__photo-doctor {
  margin-right: 28px;
  max-width: 72px;
  min-width: 72px;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  overflow: hidden;
}
.reception-doctor__photo-doctor > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.reception-doctor__week,
.reception-doctor__mounth {
  display: flex;
  justify-content: space-between;
}
.reception-doctor__week {
  font-weight: bold;
  padding-bottom: 12px;
}
.reception-doctor__name {
  font-weight: 600;
  margin-bottom: 8px;
}
.reception-doctor__specializations {
  font-size: 12px;
  color: #9f9f9f;
}
.reception-doctor__spec {
  display: inline-block;
  margin-right: 2px;
}
.reception-doctor__prevmounth,
.reception-doctor__nextmounth,
.reception-doctor__nextweek,
.reception-doctor__prevweek {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.reception-doctor__prevmounth div:first-child,
.reception-doctor__nextmounth div:first-child,
.reception-doctor__nextweek div:first-child,
.reception-doctor__prevweek div:first-child {
  padding-right: 8px;
}
@media (max-width: 440px) {
  .reception-doctor__prevmounth,
  .reception-doctor__nextmounth,
  .reception-doctor__nextweek,
  .reception-doctor__prevweek {
    font-size: 12px;
    font-weight: normal;
  }
}
.reception-doctor__prevmounth {
  text-align: left;
}
.reception-doctor__nextmounth {
  text-align: right;
}
.reception-doctor__nextweek {
  text-align: right;
}
.reception-doctor__prevweek {
  text-align: left;
}
.reception-doctor__time {
  width: calc(100% / 8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  flex-wrap: wrap;
  opacity: 0.5;
}
.reception-doctor__time span {
  width: 100%;
  text-align: center;
}
.reception-doctor__time span:last-child {
  font-size: 12px;
  display: none;
}
.reception-doctor__time.active {
  cursor: pointer;
  text-align: center;
  line-height: 1.4;
  opacity: 1;
}
.reception-doctor__time.active span:last-child {
  font-size: 12px;
  display: block;
}
.reception-doctor__time.active span:last-child {
  text-decoration: underline;
}
@media (max-width: 1400px) {
  .reception-doctor__header {
    padding: 0 16px;
  }
  .reception-doctor__content {
    padding-top: 24px;
  }
  .reception-doctor__content-block {
    padding: 16px;
  }
  .reception-doctor__photo-doctor {
    max-width: 60px;
    min-width: 60px;
    width: 60px;
    height: 60px;
  }
}
.reception-success {
  display: flex;
  justify-content: center;
  padding-top: 32px;
}
.reception-success__container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  align-items: flex-start;
  width: 100%;
}
.reception-success__title {
  font-size: 48px;
  font-weight: bold;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
}
.reception-success__time {
  font-size: 28px;
  color: var(--primary);
  font-weight: bold;
  padding-bottom: 40px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
}
.reception-success__time span {
  font-size: 24px;
  padding-top: 18px;
}
.reception-success__info {
  margin: 0 15px 0 80px;
  text-align: left;
}
.reception-success__info-text {
  background-color: var(--finish_text_bg_color);
  padding: 10px !important;
  border-radius: 12px;
  margin-bottom: 18px;
  font-weight: 600;
}
.reception-success__info div {
  padding-bottom: 18px;
}
.reception-success__info span {
  font-weight: bold;
}
.reception-success__info-map{
  width: 45%;
}
.reception-success__info-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.reception-success__info-button-container {
  display: flex;
  flex-direction: row;
  height: 50px;
  padding-bottom: 0px !important;
}

.reception-success__info-button-container div {
  padding-bottom: 7px;
  margin-right: 7px;
}
@media (max-width: 1080px) {
  .reception-success__info-container {
    flex-direction: column;
  }
  .reception-success__info-button-container {
    display: flex;
    flex-direction: column;
    height: auto;
    padding-bottom: 0px !important;
  }
  .reception-success__info-map{
    width: 85%;
    height: 300px;
    margin-left: 80px;
  }
  .reception-success__title {
    font-size: 42px;
  }

}
.reception-success__img {
  width: 64px;
  height: 64px;
  margin-right: 16px;
}
.reception-success__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.reception-branch,
.reception-date,
.reception-doctor,
.reception-services,
.reception-success {
  display: flex;
}
.reception-branch {
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 16px;
  gap: 16px;
}
.reception-branch > div {
  width: 100%;
}
.reception-branch > div > * {
  height: 100%;
  max-width: none;
}
.reception-doctor-mobile {
  display: block;
}

.reception-doctor-mobile__header {
  padding-bottom: 24px;
  border-bottom: 1px solid #707070;
}
.reception-doctor-mobile__service {
  line-height: 1;
  margin-top: 8px;
}
.reception-doctor-mobile__days {
  position: relative;
  padding: 24px 0px;
  border-bottom: 1px solid #707070;
  text-align: center;
  overflow: hidden;
}
.reception-doctor-mobile__days > div {
  display: flex;
  position: relative;
}
.reception-doctor-mobile__days-block {
  min-width: calc(100vw - (100vw/2) - 32px);
  max-width: calc(100vw - (100vw/2) - 32px);
  width: calc(100vw - (100vw/2) - 32px);
  transition: 0.1s;
  opacity: 0.5;
}
.reception-doctor-mobile__days-block.active {
  opacity: 1;
}
.reception-doctor-mobile__days-block > div:first-child {
  font-weight: bold;
  padding-bottom: 8px;
}
.reception-doctor-mobile__days-block:first-child {
  padding-left: calc(calc((100vw - 32px)/4));
}
.reception-doctor-mobile__block {
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #70707040;
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16);
  
}
.reception-doctor-mobile__name {
  font-weight: 600;
  margin-bottom: 8px;
}
.reception-doctor-mobile__specializations {
  display: flex;
  font-size: 12px;
}
.reception-doctor-mobile__doctor {
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 99;
  left: 0;
}
.reception-doctor-mobile__photo-doctor {
  margin-right: 16px;
  max-width: 52px;
  min-width: 52px;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  overflow: hidden;
}
.reception-doctor-mobile__photo-doctor > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reception-doctor-mobile__time-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin-top: 10px;
}

.reception-doctor-mobile__time-container > div{
  box-sizing: border-box;
  width: 31%;
  margin-right: 3%;
  margin-top: 10px;
  padding: 8px 8px;
  border-radius: 12px;
  border: 1px solid var(--primary);
  text-align: center;
}

.reception-doctor-mobile__time-container > div:nth-of-type(3n){
  margin-right: 0;
}
.reception-doctor-mobile__time-container > div.disabled{
  background-color: #8b8b8b;
  color: var(--text_inverse);
}

@media (max-width: 410px) {
  .reception-doctor-mobile__time-container > div{
    width: 48%;
  }
  .reception-doctor-mobile__time-container > div:nth-of-type(3n){
    margin-right: 3%;
  }
  .reception-doctor-mobile__time-container > div:nth-of-type(2n){
    margin-right: 0;
  }

}

.reception-doctor-mobile__time {
  width: calc(50vw - 48px);
  max-width: calc(50vw - 48px);
  min-width: calc(50vw - 48px);
  text-align: right;
  transition: 0.1s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.reception-doctor-mobile__time span {
  width: 100%;
}
.reception-doctor-mobile__time span:last-child {
  font-size: 12px;
  display: none;
}
.reception-doctor-mobile__time.active {
  cursor: pointer;
  text-align: right;
  line-height: 1.4;
}
.reception-doctor-mobile__time.active span:last-child {
  text-decoration: underline;
}
.reception-doctor-mobile__message {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 32px;
  box-sizing: border-box;
}
.reception-doctor-mobile__message .reception-doctor-mobile__icon {
  margin-bottom: 24px;
}
.reception-doctor-mobile__message .reception-doctor-mobile__text {
  font-size: 18px;
}
.reception-doctor-mobile__message .reception-doctor-mobile__close {
  position: absolute;
  top: 20px;
  right: 20px;
}
.reception-doctor-mobile__message.active {
  display: flex;
}
.reception-doctor-mobile__click-left,
.reception-doctor-mobile__click-right {
  position: absolute !important;
  top: 0;
  height: 100%;
  width: calc(100%/3);
  z-index: 99;
}
.reception-doctor-mobile__click-left {
  left: 0;
}
.reception-doctor-mobile__click-right {
  right: 0;
}
@media (max-width: 1650px) {
  .reception__tab-block {
    padding: 16px 12px;
  }
  .reception__tab-title {
    font-size: 18px;
  }
  .reception__tab-subtitle {
    font-size: 14px;
  }
  .reception__tab-icon {
    padding-right: 6px;
  }
}
@media (max-width: 1400px) {
  .reception__tab-block {
    padding: 16px 12px;
  }
  .reception__tab-title {
    font-size: 18px;
  }
  .reception__tab-subtitle {
    font-size: 14px;
  }
  .reception__tab-icon {
    padding-right: 6px;
  }
}
@media (max-width: 1200px) {
  .reception-services__type-icon {
    display: none;
  }
}
@media (max-width: 991px) {
  .reception__tab-block {
    padding: 16px;
  }
  .reception__tab-title {
    font-size: 16px;
  }
  .reception__tab-subtitle {
    font-size: 12px;
  }
  .reception-date__container {
    flex-direction: column;
  }
  .reception-date__content {
    flex: none;
    text-align: left;
    margin-right: 0px;
  }
  .reception-date__form {
    width: 100%;
    max-width: 500px;
  }
}
@media (max-width: 768px) {
  .reception__tab {
    display: none;
  }
  .reception__content {
    padding-top: 24px;
  }
  .reception__mobile {
    display: block;
  }
  .reception__time-block {
    width: calc(100% / 2 - 16px);
  }
  .reception-branch {
    grid-template-columns: 1fr 1fr;
  }
  .reception-services__type-service {
    flex-direction: column;
    padding: 0px 24px;
  }
  .reception-services__type-block {
    width: 100%;
    justify-content: center;
    padding: 24px 0px;
    box-sizing: border-box;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
  .reception-services__type-block:first-child {
    padding-bottom: 24px;
    padding-top: 24px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .reception-services__type-block:last-child {
    padding-bottom: 24px;
    padding-top: 24px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .reception-services__type-block.active {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  .reception-services__block {
    min-height: 60px;
  }
  .reception-date__title {
    display: none;
  }
  .reception-date__time {
    font-size: 28px;
  }
  .reception-date__button-done {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0px;
    z-index: 9999;
    padding: 24px;
    box-sizing: border-box;
  }
  .reception-date__button-done * > button {
    width: 100%;
  }
  .reception-date__comment {
    padding-bottom: 80px;
  }
  .reception-success__container {
    text-align: center;
    align-items: center;
  }
  .reception-success__info {
    margin: 0;
  }
  .reception-success__info-container {
    flex-direction: column;
  }
  .reception-success__title {
    font-size: 32px;
    /* order: 1; */
    width: 100%;
    flex-direction: column;
  }
  .reception-success__title-text {
    align-self: start;
  }
  .reception-success__time {
    font-size: 36px;
    /* order: 2; */
  }
  .reception-success__time span {
    font-size: 18px;
  }
  /* .reception-success__info {
    order: 3;
  } */
  .reception-success__info-map{
    width: 100%;
    margin: 0;
  }
  .reception-success__img {
    width: 64px;
    height: 64px;
    order: 0;
    padding-bottom: 40px;
    margin: 0;
  }
}
@media (max-width: 768px) and (max-width: 480px) {
  .reception-services__type-block:first-child {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  .reception-services__type-block:last-child {
    padding-bottom: 24px;
    padding-top: 24px;
  }

  .reception-services__type-block.active {
    padding-bottom: 24px;
    padding-top: 24px;
  }
}
@media (max-width: 580px) {
  .reception-branch {
    grid-template-columns: 1fr;
  }
  .reception-branch > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .reception-branch * > button {
    max-width: 100%;
  }
  .reception-branch * > button .button-branch__container {
    padding: 8px 16px;
    min-height: 48px;
  }
}
@media (max-width: 440px) {
  .reception__mobile-title {
    font-size: 20px;
    padding-bottom: 10px;
  }
  .reception__mobile-step {
    font-size: 14px;
  }
  .reception-success__title {
    font-size: 28px;
  }
  .reception-success__time {
    font-size: 28px;
  }
  .reception-success__time span {
    font-size: 18px;
  }
  .reception-success__img {
    width: 64px;
    height: 64px;
    padding-bottom: 16px;
    margin: 0;
  }
  .reception-date__time {
    font-size: 28px;
  }
  .reception-services__type-service {
    flex-direction: column;
  }
  .reception-services__type-block {
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    justify-content: center;
    text-align: center;
  }
  .reception-services__type-icon {
    display: none;
  }
}
.select-type-reception {
  display: flex;
  width: 100%;
  height: calc(100vh - 330px);
}
.select-type-reception > div {
  display: flex;
}
.select-type-reception > div .button-block:first-child {
  margin-right: 24px;
}
@media (max-width: 520px) {
  .select-type-reception > div .button-block:first-child {
    margin-right: 0px;
    margin-bottom: 24px;
  }
}
@media (max-width: 520px) {
  .select-type-reception > div {
    flex-direction: column;
    width: 100%;
  }
  .select-type-reception > div .button-block button {
    max-width: 100%;
  }
}
.all-doctors {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.all-doctors__item {
  box-sizing: border-box;
  width: 32.5%;
  min-width: 250px;
  padding: 22px;
  background-color: #fff;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.all-doctors > :only-child {
  width: 100%;
}
.all-doctors__item:first-child:nth-last-child(2),
.all-doctors__item:first-child:nth-last-child(2) ~ .all-doctors__item {
  width: 49%;
}

.all-doctors__item-content {
  width: 70%;
}

.all-doctors__item-name {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.all-doctors__specializations {
  margin-top: 11px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.all-doctors__spec {
  display: inline-block;
  margin-right: 2px;
}
.all-doctors__item-photo {
  margin-right: 16px;
  max-width: 72px;
  min-width: 72px;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  overflow: hidden;
}
.all-doctors__item-photo > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.all-doctors__item-photo, .reception-doctor__photo-doctor {
  cursor: zoom-in;
}
.all-doctors__item-photo:hover, .reception-doctor__photo-doctor:hover {
  transform: scale(1.1);
}
.doctor__about {
  display: flex;
  margin-top: 11px;
  font-size: 14px;
  cursor: pointer;
  color: #9f9f9f;
}
.doctor__about:hover {
  color: #4e4e4e;
}
.links-doctors h2 {
  font-size: 32px;
  padding-bottom: 32px;
  font-weight: bold;
}
.links-doctors__item {
  padding: 22px;
  background-color: #fff;
  box-shadow: 0px 8px 25px 0px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}
.links-doctors__item > div {
  font-weight: 600;
}
.links-doctors__item a {
  margin-left: 6px;
  color: var(--primary);
}

@media (max-width: 1200px) {
  .all-doctors__item {
    width: 49%;
    min-width: 250px;
    margin-bottom: 16px;
  }
}
@media (max-width: 1000px) {
  .all-doctors__item {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .links-doctors h2 {
    font-size: 24px;
  }
  .links-doctors__item > div {
    width: 140px;
  }
  .links-doctors__item a {
    flex: 1;
  }

  .all-doctors__item:first-child:nth-last-child(2),
.all-doctors__item:first-child:nth-last-child(2) ~ .all-doctors__item {
  width: 100%;
}

}

.reception-doctor-mobile__calendar {
  width: 100%;
  box-sizing: border-box;
}

.reception-doctor-mobile__calendar-month {
  display: flex;
  box-sizing: border-box;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--primary);
  margin-bottom: 10px;
  width: 100%;  
  height: 50px;
  border-radius: 14px;
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16);
}

.reception-doctor-mobile__calendar-days {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  border-top: 1px solid var(--primary);
  border-radius: 12px;
  width: 100%;
  height: 50px;
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.16);
}

.reception-doctor-mobile__calendar-button:first-child {
  border-radius: 12px 0 0 12px;
}

.reception-doctor-mobile__calendar-button:last-child {
  border-radius: 0 12px 12px 0;
}

.reception-doctor-mobile__calendar-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  width: 48px;
  height: 100%;
  text-align: center;
  color: var(--text_inverse);
}
.reception-doctor-mobile__calendar-days > div{
  border-bottom: 1px solid var(--primary);
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
 }

 .reception-doctor-mobile__calendar-days > div:not(:first-child):not(:last-child) {
  flex-grow: 1;
 }

 .reception-doctor-mobile__calendar-button:active,
 .reception-doctor-mobile__calendar-days > div:first-child:active, 
 .reception-doctor-mobile__calendar-days > div:last-child:active {
  background-color: var(--primary);
  color: var(--text_inverse);
 }

.reception-doctor-mobile__calendar-days > div.active {
  margin-top: 10px;
  border: 1px solid var(--primary);
  border-radius: 12px 12px 0px 0px;
  border-bottom: none;
  background-color: #ffffff;
}

.reception-doctor-mobile__calendar-days > div.empty {
  color: #dbdbdb;
}